import '../scss/index.scss';

import { Application } from '@hotwired/stimulus';
import LazyImage from './controllers/lazy-image_controller';
import ParallaxImage from './controllers/parallax-image_controller';
import Parallax2Image from './controllers/parallax2-image_controller';
import IntroAnim from './controllers/intro-anim_controller';
import ClipPathElement from './controllers/clip-path-element_controller';

const application = Application.start();
application.register('lazy-image', LazyImage);
application.register('parallaxe-image', ParallaxImage);
application.register('parallaxe2-image', Parallax2Image);
application.register('intro-anim', IntroAnim);
application.register('clip-path-element', ClipPathElement);
