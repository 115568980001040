import {Controller} from '@hotwired/stimulus';
import {IntersectionController, useIntersection} from "stimulus-use";
import gsap from "gsap";

export default class extends Controller {
  options = {
    //threshold: 0.2, // 0 by default
  };
  connect() {
    const [observeIntersection, unobserveIntersection] = useIntersection(
        this,
        this.options
    );
    gsap.set(this.element, {
      opacity: 0,
    });
    this.animPlayed = false;
  }

  animateClipPath() {
    gsap.to(this.element, {
      opacity: 1,
      duration: 0.5,
      delay:0.8,
    });
    gsap.to(this.element, {
      clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
      duration: 2,
      ease: "power2.out",
      delay:0.7,
    });
  }
  animateClipPathAlt() {
    gsap.to(this.element, {
      opacity: 1,
      duration: 0.5,
      delay:0.4,
    });
    gsap.to(this.element, {
      clipPath: "circle(100%)",
      duration: 3,
      ease: "power4.inOut",
      onStart:() => {
        setTimeout(()=>  this.element.classList.add('complete'), 1000);
      },
    });
  }

  setClipPath() {
    if (this.element.classList.contains("clip-from-left")) {
      // console.log(`From left`);

      this.element.style.clipPath = "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)";
      this.animateClipPath();
    }

    if (this.element.classList.contains("clip-from-right")) {
      // console.log(`From right`);

      this.element.style.clipPath =
        "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)";
      this.animateClipPath();
    }

    if (this.element.classList.contains("clip-from-top")) {
      // console.log(`From top`);

      this.element.style.clipPath = "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)";
      this.animateClipPath();
    }

    if (this.element.classList.contains("clip-from-bottom")) {
      // console.log(`From bottom`);

      this.element.style.clipPath =
        "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)";
      this.animateClipPath();
    }

    if (this.element.classList.contains("clip-from-center")) {
      // console.log(`From bottom`);

      this.element.style.clipPath =
        "polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%)";
      this.animateClipPath();
    }

    if (this.element.classList.contains("clip-from-circle")) {
      // console.log(`From center`);

      this.element.style.clipPath = "circle(0%)";
      this.animateClipPathAlt();
    }
  }

  appear() {
    // console.log("APPEARED");
    if (!this.animPlayed) {
      this.setClipPath();
    }
  }
  disappear() {
    this.animPlayed = true;
  }
}
